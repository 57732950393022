<template>
  <div class="goods">
    <van-form @submit="submitForm">
      <van-cell-group inset>
        <van-field
          v-model="department"
          name="department"
          label="部门"
          placeholder="部门"
          :rules="[{ required: true, message: '请填写部门' }]"
        />
        <van-field
          v-model="name"
          type="text"
          name="name"
          label="姓名"
          placeholder="姓名"
          :rules="[{ required: true, message: '请填写姓名' }]"
        />
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          签到
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {
  Tag,
  Col,
  Icon,
  Cell,
  CellGroup,
  Swipe,
  Toast,
  SwipeItem,
  ActionBar,
  ActionBarIcon,
  ActionBarButton,
  Form,
  Field,
  Button,
} from 'vant';
import axios from 'axios';

export default {
  components: {
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [ActionBar.name]: ActionBar,
    [ActionBarIcon.name]: ActionBarIcon,
    [ActionBarButton.name]: ActionBarButton,
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
  },

  data() {
    return {
      department: '',
      name: '',
    };
  },

  methods: {
    formatPrice() {
      return '¥' + (this.goods.price / 100).toFixed(2);
    },

    onClickCart() {
      this.$router.push('cart');
    },

    sorry() {
      Toast('暂无后续逻辑~');
    },

    submitForm(values) {
      console.log(values)
      axios.post('https://form.mengxiangjing.com/api/signin_db', {
        ...values
      }).then((res) => {
        if (res.status === 200) {
          if (res.data['code'] === 200) {
            Toast(res.data['msg'])
          } else {
            Toast('系统繁忙，请稍后重试')
          }
        } else {
          Toast('系统繁忙，请稍后重试')
        }
      }).catch(e => {
        console.log(e)
        Toast('网络繁忙，请稍后重试')
      })
    }
  },
};
</script>

<style lang="less">
.goods {
  padding-top: 20px;
  display: flex;
  margin: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  // align-items: center;
}
</style>
